import React from "react"
import PageTransition from 'gatsby-plugin-page-transitions';
// import { Link } from "gatsby"
import Layout from "../../components/layout"
import WhoAreWe from "../../components/who-are-we"
import SEO from "../../components/seo"

class TacosDeLyon extends React.Component {
  render = () => (
    
        <Layout>
            <SEO title="Home" keywords={[`gatsby`, `application`, `react`]} />
            <div className="hero hero--smallest hero--project-tacosdelyon inuit-box inuit-box--flush">
                <h2 className="hero__message hero__message--bigger hero__message--center"></h2>
            </div>
            <article className="article project inuit-u-p0">
                <div className="article__body article__body--has-extra">
                    <aside className="article__extra">
                        <p className="article__para inuit-u-p0">
                            <a className="project__link" href="https://tacos.ma/" title="" target="_blank">
                                <img src="/img/clients/tacos-icons-app.png" alt="" className="project__logo" />
                            </a>
                        </p>
                        <p className="article__para inuit-u-p0">
                            <span className="project__detail">TACOS DE LYON.</span>
                            <span className="project__detail project__detail--emphasis">
                                <a className="project__link" href="https://tacos.ma/" title="See Dolfn!" target="_blank">tacos.ma</a>
                            </span>
                            <span className="project__detail">Year 2016</span>
                        </p>
                        <ul className="article__share-list">
                            <li className="article__share-item">
                                <a href="#" className="article__share-link article__share-link--facebook" target="blank" title="Share on Facebook"></a>
                            </li>
                            <li className="article__share-item">
                                <a href="#" className="article__share-link article__share-link--twitter" target="blank" title="Share on Twitter"></a>
                            </li>
                            <li className="article__share-item">
                                <a href="#" className="article__share-link article__share-link--linkedin" target="blank" title="Share on Linkedin"></a>
                            </li>
                            <li className="article__share-item">
                                <a href="#" onclick="javascript:window.open(this.href, '', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=600,width=600');return false;"
                                    className="article__share-link article__share-link--gplus" target="blank" title="Share on Google Plus"></a>
                            </li>
                        </ul>
                    </aside>
                    <h1 className="article__heading">Plateforme de commande en ligne & d’administration <strong>TACOS DE LYON</strong>.</h1>
                    <p className="article__para">
                        Dans cette plateforme de commerce électronique, nous avons établi une nouvelle norme en ligne dans le
                        secteur du fast food. Combinant l'affichage de produits magnifiques & délicieux, elle présente un
                        équilibre délicat entre design, technologie et fonctionnaliteś. <br />Les sandwich, tacos et burgers sont
                        entièrement personnalisables comme si vous êtes face à un caissièr.
                    </p>
                    <p className="article__para">
                        <span className="project__detail project__detail--small">Conception graphique, Webdesign, Developpement web &
                            mobile</span>
                    </p>
                </div>
                <hr style={{ backgroundColor: "white", height: "1px", borderTop: "1px solid white"}} />
                <div className="article__body">
                    <h2 className="article__heading">Le challenge</h2>

                    <p className="article__para">
                        Optimisation de l'identité de la marque TACOS DE LYON pour une utilisation dans un environnement en ligne.
                    </p>

                    <p className="article__belt">
                        <img className="article__belt-image" src="/img/projects/tacosdelyon/banner-branding.jpg" alt="mobile-apps" />
                    </p>

                    <h2 className="article__heading">La typographie</h2>

                </div>

                <p className="article__belt inuit-u-mb0">
                    <img className="article__belt-image" src="/img/projects/tacosdelyon/banner-typo.jpg" alt="branding" />
                </p>

                <hr style={{ backgroundolor: "white", height: "1px", borderTop: "1px solid white" }} />


                <div className="article__body">
                    <h2 className="article__heading">Photos des produits</h2>
                    <p className="article__para">
                        C’est en seulement quelques secondes que les visiteurs réalisent leurs choix... Nous avons essayer à
                        travers la disposition des produits de mettre en valeurs chaque produit, y dédier un grand espace ainsi le
                        tacos prend un aspect délicieux plus attrayant et plus appétissant
                    </p>
                </div>

                <p className="article__belt inuit-u-mt0">
                    <img className="article__belt-image" src="/img/projects/tacosdelyon/banner-product.jpeg" alt="mobile-apps" />
                </p>

                <div className="article__body">
                    <h2 className="article__heading">Une expérience unique</h2>
                </div>

                <p className="article__tacos">
                    <img className="article__belt-image" src="/img/projects/tacosdelyon/experience.jpg" alt="branding" />
                </p>

            </article>
            <WhoAreWe />
        </Layout>
    
  )

}

export default TacosDeLyon
